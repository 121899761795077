import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCalendarAlt,
  faVideo,
  faFilm,
  faEye,
  faCamera,
  faQuestionCircle,
  faBook,
} from "@fortawesome/free-solid-svg-icons";
import { faNewspaper, faEnvelope } from "@fortawesome/free-regular-svg-icons";

export default function OtherLinkView() {
  return (
      <div className="menu-item-link">
        <ul className="list-unstyled">
          <li>
            <a href="http://www.haiduong.gov.vn/Pages/default.aspx" target="_blank">
                <span>
                  <FontAwesomeIcon icon={faCalendarAlt} />
                </span>
              Cổng thông tin điện tử
            </a>
          </li>
          <li>
            <a href="http://www.haiduongtv.com.vn/" target="_blank">
                <span>
                  <FontAwesomeIcon icon={faVideo} />
                </span>
              Truyền hình Hải Dương
            </a>
          </li>
          <li>
            <a href="http://www.haiduongtv.com.vn/" target="_blank">
                <span>
                  <FontAwesomeIcon icon={faVideo} />
                </span>
              Phát thanh Hải Dương
            </a>
          </li>
          <li>
            <a href="https://baohaiduong.vn/" target="_blank">
                <span>
                  <FontAwesomeIcon icon={faNewspaper} />
                </span>
              Báo Hải Dương
            </a>
          </li>
          <li>
            <a href="https://haiduongtourism.vn/" target="_blank">
                <span>
                  <FontAwesomeIcon icon={faEye} />
                </span>
              Du lịch Hải Dương
            </a>
          </li>
          
          <li>
            <a href="http://haiduong.gov.vn/Trang/danh-sach-y-kien.aspx" target="_blank">
                <span>
                  <FontAwesomeIcon icon={faQuestionCircle} />
                </span>
              Xin ý kiến văn bản
            </a>
          </li>
          <li>
            <a href="http://dichvucong.haiduong.gov.vn/portaldvc/KenhTin/van-ban.aspx" target="_blank" >
                <span>
                  <FontAwesomeIcon icon={faBook} />
                </span>
              Văn bản pháp quy
            </a>
          </li>
          <li>
            <a href="http://vbdh.haiduong.gov.vn/vbdh.nsf" target="_blank">
                <span>
                  <FontAwesomeIcon icon={faBook} />
                </span>
              VB chỉ đạo điều hành
            </a>
          </li>
          <li>
                  <a href="https://dichvucong.haiduong.gov.vn/dichvucong/khaosat" target="_blank">
                <span>
                  <FontAwesomeIcon icon={faEnvelope} />
                </span>
              Hòm thư góp ý
            </a>
          </li>
        </ul>
      </div>
  );
}
